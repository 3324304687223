export const payment = {
  state: {
    paymentListStatus: 0
  },
  mutations: {
    set_payment_list_status(state, status) {
      state.paymentListStatus = status;
    }
  },
  actions: {
    payment_list_needs_refresh({ commit }) {
      commit("set_payment_list_status", 1);
    },
    payment_list_refreshed({ commit }) {
      commit("set_payment_list_status", 0);
    }
  },
  getters: {
    paymentListNeedsRefresh: state => state.paymentListStatus === 1
  }
};
