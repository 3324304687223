export const role = {
  state: {
    roleListStatus: 0
  },
  mutations: {
    set_role_list_status(state, status) {
      state.roleListStatus = status;
    }
  },
  actions: {
    role_list_needs_refresh({ commit }) {
      commit("set_role_list_status", 1);
    },
    role_list_refreshed({ commit }) {
      commit("set_role_list_status", 0);
    }
  },
  getters: {
    roleListNeedsRefresh: state => state.roleListStatus === 1
  }
};
