export const board = {
  state: {
    boardListStatus: 0
  },
  mutations: {
    set_board_list_status(state, status) {
      state.boardListStatus = status;
    }
  },
  actions: {
    board_list_needs_refresh({ commit }) {
      commit("set_board_list_status", 1);
    },
    board_list_refreshed({ commit }) {
      commit("set_board_list_status", 0);
    }
  },
  getters: {
    boardListNeedsRefresh: state => state.boardListStatus === 1
  }
};
