import Vue from "vue";
import Router from "vue-router";
import MainLayout from "./layouts/MainLayout.vue";
import store from "./store";

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "root",
      redirect: "home",
      component: MainLayout,
      children: [
        {
          path: "home",
          name: "home",
          meta: {
            icon: "home",
            title: "Početna stranica",
            subtitle: "Status"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/HomeContent.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "userlist",
          name: "user-list",
          meta: {
            icon: "person",
            title: "Baza korisnika",
            subtitle: "Lista korisnika"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/UserList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "useredit/:id",
          name: "user-edit",
          meta: {
            icon: "person",
            title: "Baza korisnika",
            subtitle: "Uređivanje korisnika"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/UserEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "rolelist",
          name: "role-list",
          meta: {
            icon: "folder_shared",
            title: "Uloge",
            subtitle: "Lista uloga"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/RoleList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "roleedit/:id",
          name: "role-edit",
          meta: {
            icon: "folder_shared",
            title: "Uloge",
            subtitle: "Uređivanje uloge"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/RoleEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "companylist",
          name: "company-list",
          meta: {
            icon: "location_city",
            title: "Poduzeća",
            subtitle: "Lista poduzeća"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/CompanyList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "companyedit/:id",
          name: "company-edit",
          meta: {
            icon: "location_city",
            title: "Poduzeća",
            subtitle: "Uređivanje poduzeća"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/CompanyEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "unitlist/:parent/:pid",
          name: "unit-list",
          meta: {
            icon: "folder_shared",
            title: "Organizacijske jedinice",
            subtitle: "Lista organizacijskih jedinica"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/UnitList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "unitedit/:id/:parent/:pid?",
          name: "unit-edit",
          meta: {
            icon: "folder_shared",
            title: "Organizacijske jedinice",
            subtitle: "Uređivanje organizacijske jedinice"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/UnitEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "userroleunitlist/:parent/:pid",
          name: "userroleunit-list",
          meta: {
            icon: "people",
            title: "Veza osoba - uloga - org. jedinica",
            subtitle: "Lista veza osoba - uloga - org. jedinica"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/UserRoleUnitList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "userroleunitedit/:id/:parent/:pid",
          name: "userroleunit-edit",
          meta: {
            icon: "people",
            title: "Veza osoba - uloga - org. jedinica",
            subtitle: "Uređivanje veze osoba - uloga - org. jedinica"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/UserRoleUnitEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "membershiplist",
          name: "membership-list",
          meta: {
            icon: "card_membership",
            title: "Vrsta članarina",
            subtitle: "Lista vrsta članarina"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/MembershipList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "membershipedit/:id",
          name: "membership-edit",
          meta: {
            icon: "card_membership",
            title: "Vrsta članarina",
            subtitle: "Uređivanje vrste članarine"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/MembershipEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "membershipstatus",
          name: "membership-status",
          meta: {
            icon: "drafts",
            title: "Pregled članarina",
            subtitle: "Status plaćanja članarina"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/MembershipStatus.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "invoicelist",
          name: "invoice-list",
          meta: {
            icon: "receipt",
            title: "Računi",
            subtitle: "Lista računa"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/InvoiceList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "invoiceedit/:id",
          name: "invoice-edit",
          meta: {
            icon: "receipt",
            title: "Računi",
            subtitle: "Uređivanje računa"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/InvoiceEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "paymentlist/:invoiceid",
          name: "payment-list",
          meta: {
            icon: "payment",
            title: "Plaćanja računa",
            subtitle: "Lista uplata"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/PaymentList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "paymentedit/:id/:invoiceid",
          name: "payment-edit",
          meta: {
            icon: "payment",
            title: "Plaćanja računa",
            subtitle: "Uređivanje plaćanja računa"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/PaymentEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "quotelist",
          name: "quote-list",
          meta: {
            icon: "list_alt",
            title: "Ponude",
            subtitle: "Lista ponuda"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/QuoteList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "quoteedit/:id",
          name: "quote-edit",
          meta: {
            icon: "list_alt",
            title: "Ponude",
            subtitle: "Uređivanje ponude"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/QuoteEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "boardlist",
          name: "board-list",
          meta: {
            icon: "group",
            title: "Odbori",
            subtitle: "Lista odbora"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/BoardList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "boardedit/:id",
          name: "board-edit",
          meta: {
            icon: "group",
            title: "Odobri",
            subtitle: "Uređivanje odbora"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/BoardEdit.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "boarduserrolelist/:id/:name?",
          name: "boarduserrole-list",
          meta: {
            icon: "people",
            title: "Osobe i funkcije u odboru",
            subtitle: "Lista osoba i funkcija u odboru"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/BoardUserRoleList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "reportlist",
          name: "report-list",
          meta: {
            icon: "description",
            title: "Izvještaji",
            subtitle: "Lista izvještaja"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/ReportList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "registrationlist",
          name: "registration-list",
          meta: {
            icon: "person_add",
            title: "Prijave za članstvo",
            subtitle: "Lista prijava za članstvo u HRO CIGRE"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/RegistrationList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "unpaidinvoicelist",
          name: "unpaidinvoice-list",
          meta: {
            icon: "invoice",
            title: "Računi",
            subtitle: "Lista nepodmirenih obaveza poduzeća"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/UnpaidInvoiceList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "eventlist",
          name: "event-list",
          meta: {
            icon: "event_note",
            title: "Događaji",
            subtitle: "Lista događaja"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/EventList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        },
        {
          path: "eventregistrationlist/:id",
          name: "event-registration-list",
          meta: {
            icon: "event_note",
            title: "Događaji",
            subtitle: "Lista prijava na događaj"
          },
          components: {
            toolbar: () => import("./components/MainHeader.vue"),
            drawer: () => import("./components/MainMenu.vue"),
            content: () => import("./views/EventRegistrationList.vue"),
            footer: () => import("./components/MainFooter.vue")
          }
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      meta: {
        isPublic: true,
        isLogin: true
      },
      component: () => import("./views/Login.vue")
    },
    {
      path: "/mq",
      name: "mq",
      meta: {
        isPublic: true
      },
      component: () => import("./views/MemberQuestion.vue")
    },
    {
      path: "/eventregistration",
      name: "er",
      meta: {
        isPublic: true
      },
      component: () => import("./views/EventRegistration.vue")
    }
  ]
});

const _store = store;

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => !record.meta || !record.meta.isPublic)) {
    if (!_store.getters.isLoggedIn) {
      next({
        path: "/login",
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else if (to.matched.some(record => record.meta && record.meta.isLogin)) {
    if (_store.getters.isLoggedIn) {
      next("/home");
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router;
