<template>
  <v-menu offset-y class="menu-top" transition="slide-y-transition">
    <template v-slot:activator="{ on }">
      <v-btn color="primary" dark v-on="on">
        <v-icon large>account_circle</v-icon>
        <v-spacer class="mr-2 hidden-xs-only"></v-spacer>
        <div class="hidden-xs-only">{{ currentUser }}</div>
      </v-btn>
    </template>
    <v-list>
      <v-list-item
        v-for="item in items"
        :key="item.key"
        v-on:click="menuItemClicked(item.key)"
      >
        <v-list-item-title>{{ item.title }}</v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import Vue from "vue";
import AsyncComputed from "vue-async-computed";

Vue.use(AsyncComputed);

export default {
  data: () => ({
    items: [
      { key: "mydata", title: "Moji podaci" },
      { key: "logout", title: "Odjava" }
    ]
  }),
  asyncComputed: {
    currentUser() {
      return this.$http
        .get("/auth/current-user.php")
        .then(response => response.data.userDisplayName)
        .catch(() => "?");
    }
  },
  methods: {
    menuItemClicked: function(itemId) {
      switch (itemId) {
        case "mydata":
          break;
        case "logout":
          this.$store.dispatch("logout", this);
          break;
      }
    }
  }
};
</script>

<style scoped>
.menu-top {
  z-index: 1001;
}
</style>
