import Vue from "vue";
import Vuex from "vuex";

import { login } from "./store/login";
import { user } from "./store/user";
import { role } from "./store/role";
import { company } from "./store/company";
import { unit } from "./store/unit";
import { payment } from "./store/payment";
import { invoice } from "./store/invoice";
import { quote } from "./store/quote";
import { board } from "./store/board";

Vue.use(Vuex);

const store = new Vuex.Store({
  modules: {
    login,
    user,
    role,
    company,
    unit,
    payment,
    invoice,
    quote,
    board
  }
});

export default store;
