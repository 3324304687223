export const invoice = {
  state: {
    invoiceListStatus: 0
  },
  mutations: {
    set_invoice_list_status(state, status) {
      state.invoiceListStatus = status;
    }
  },
  actions: {
    invoice_list_needs_refresh({ commit }) {
      commit("set_invoice_list_status", 1);
    },
    invoice_list_refreshed({ commit }) {
      commit("set_invoice_list_status", 0);
    }
  },
  getters: {
    invoiceListNeedsRefresh: state => state.invoiceListStatus === 1
  }
};
