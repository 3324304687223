export const quote = {
  state: {
    quoteListStatus: 0
  },
  mutations: {
    set_quote_list_status(state, status) {
      state.quoteListStatus = status;
    }
  },
  actions: {
    quote_list_needs_refresh({ commit }) {
      commit("set_quote_list_status", 1);
    },
    quote_list_refreshed({ commit }) {
      commit("set_quote_list_status", 0);
    }
  },
  getters: {
    quoteListNeedsRefresh: state => state.quoteListStatus === 1
  }
};
