<template>
  <v-app id="cigre">
    <v-navigation-drawer
      class="drawer-top"
      v-model="drawer"
      fixed
      app
      clipped
      dark
    >
      <router-view name="drawer"> </router-view>
    <div style="position: absolute; bottom: 0; width: 100%;font-size:x-small;color:white;" class="ml-3">
        App Version: {{ appVersion+'-'+gitCommitHash }}
    </div>
    </v-navigation-drawer>
    <v-app-bar
      clipped-left
      fixed
      app
      color="primary"
      extended
      extension-height="48"
      class="toolbar-back"
    >
      <v-app-bar-nav-icon
        @click.stop="drawer = !drawer"
        class="mr-2"
      ></v-app-bar-nav-icon>
      <router-view name="toolbar"></router-view>
      <v-spacer></v-spacer>
      <UserMenu />
    </v-app-bar>

    <v-main>
      <v-container
        fluid
        fill-height
        :class="{ 'pa-0': $vuetify.breakpoint.xsOnly }"
      >
        <v-row>
          <v-col xs12>
            <keep-alive
              include="UserList,RoleList,CompanyList,UserRoleUnitList,MembershipList,MembershipStatus,InvoiceList,PaymentList,QuoteList,BoardList,BoardUserRoleList,UnitList,RegistrationList,EventList,EventRegistrationList"
            >
              <router-view name="content" :key="$route.fullPath"></router-view>
            </keep-alive>
          </v-col>
        </v-row>
      </v-container>
    </v-main>
    <!--
    <v-footer app dark>
      <v-container fluid fill-height pa-0>
        <v-row no-gutters>
          <v-col xs="12" md="2" class="text-xs-center">
            <v-alert
              v-model="serverOk"
              type="success"
              transition="slide-x-transition"
              class="mt-0 mb-0 mr-3 pa-0"
              >Server spojen</v-alert
            >
            <v-alert
              v-model="serverError"
              type="error"
              transition="slide-x-transition"
              class="mt-0 mb-0 mr-3 pa-0"
              >Greška u spajanju na server</v-alert
            >
          </v-col>
          <v-col xs="12" md="10">
            <router-view name="footer"></router-view>
          </v-col>
        </v-row>
      </v-container>
    </v-footer>
    -->
  </v-app>
</template>

<script>
import UserMenu from "@/components/UserMenu";

export default {
  data: () => ({
    drawer: null,
    appVersion: process.env.APP_VERSION,
    gitCommitHash: process.env.GIT_COMMIT_HASH.substring(0, 7)
  }),
  computed: {
    serverOk() {
      return this.$store.getters.isServerOk;
    },
    serverError() {
      return !this.$store.getters.isServerOk;
    }
  },
  components: {
    UserMenu
  }
};
</script>

<style>
.v-application a {
  text-decoration: none;
}
.card--flex-toolbar {
  position: absolute !important;
  top: -48px;
  z-index: 1000;
  height: calc(100% + 24px);
  width: calc(100% - 24px);
  /* overflow: auto; */
}
.card--flex-toolbar-xs {
  position: absolute !important;
  top: -48px;
  z-index: 1000;
  height: 100%;
  width: 100%;
  /* overflow: auto; */
}
.xl-table .v-data-table__wrapper {
  overflow-y: auto !important;
  height: calc(100vh - 330px) !important;
}
.lg-table .v-data-table__wrapper {
  overflow-y: auto !important;
  height: calc(100vh - 280px) !important;
}
.sticky-xs {
  position: fixed;
  width: inherit;
  z-index: 1001;
}
.sticky-lg {
  position: fixed;
  width: calc(100% - 280px);
  z-index: 1001;
}
.form-container {
  border: solid 2px darkgrey;
}
.small-width {
  max-width: 200px !important;
}
.medium-width {
  max-width: 300px !important;
}
.loading-visible {
  display: block;
  position: absolute;
  opacity: 0.8;
  left: calc(50% - 25px);
  top: 100px;
}
.loading-hidden {
  display: none;
}
.sheet-footer {
  background-color: black;
  color: white;
  opacity: 0.36;
}
.select-icon {
  position: absolute;
  left: 3px;
  top: 3px;
}
.theme--light.v-subheader {
  color: indigo !important;
}
</style>

<style scoped>
.drawer-top {
  z-index: 1001;
}
.content-overflow {
  overflow: auto;
}
.smaller-font {
  font-size: 8pt;
}
.larger-font {
  font-size: 12pt;
}
.toolbar-back {
  background: url("../assets/cigrebg.jpg");
}
</style>
