import Vue from "vue";
import "./plugins/vuetify";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import vuetify from "./plugins/vuetify";

Vue.config.productionTip = false;

const base = axios.create({
  baseURL: process.env.VUE_APP_API,
  withCredentials: true
});

const _router = router;
const _store = store;

Vue.prototype.$http = base;
Vue.prototype.$formatter = new Intl.NumberFormat("hr-HR", {
  style: "currency",
  currency: "HRK",
  currencyDisplay: 'symbol'
});
Vue.prototype.$formatterEn = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "HRK",
  currencyDisplay: 'symbol'
});
Vue.prototype.$formatterEUR = new Intl.NumberFormat("hr-HR", {
  style: "currency",
  currency: "EUR",
  currencyDisplay: 'symbol'
});
Vue.prototype.$formatterEUREn = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "EUR",
  currencyDisplay: 'symbol'
});

const _vue = new Vue({
  router,
  store,
  vuetify,
  render: h => h(App)
});

base.interceptors.response.use(
  response => {
    _store.dispatch("server_ok");
    return response;
  },
  error => {
    if (error.response.status === 401) {
      // unauthorized, redirect to login
      if (_store.getters.isLoggedIn) _store.dispatch("logout", _vue);
      else _router.push("/login");
    } else {
      // store dispatch error getting data
      _store.dispatch("server_error");
    }
    return error;
  }
);

_vue.$mount("#app");
