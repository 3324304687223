export const user = {
  state: {
    userListStatus: 0
  },
  mutations: {
    set_user_list_status(state, status) {
      state.userListStatus = status;
    }
  },
  actions: {
    user_list_needs_refresh({ commit }) {
      commit("set_user_list_status", 1);
    },
    user_list_refreshed({ commit }) {
      commit("set_user_list_status", 0);
    }
  },
  getters: {
    userListNeedsRefresh: state => state.userListStatus === 1
  }
};
