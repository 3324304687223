export const unit = {
  state: {
    unitListStatus: 0
  },
  mutations: {
    set_unit_list_status(state, status) {
      state.unitListStatus = status;
    }
  },
  actions: {
    unit_list_needs_refresh({ commit }) {
      commit("set_unit_list_status", 1);
    },
    unit_list_refreshed({ commit }) {
      commit("set_unit_list_status", 0);
    }
  },
  getters: {
    unitListNeedsRefresh: state => state.unitListStatus === 1
  }
};
