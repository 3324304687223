import Vue from "vue";
import Vuetify from "vuetify/lib";

Vue.use(Vuetify);

export default new Vuetify({
  icons: {
    iconfont: "md"
  },
  theme: {
    themes: {
      light: {
        primary: "#8bc34a",
        secondary: "#2196f3",
        accent: "#3f51b5",
        error: "#f44336",
        warning: "#cddc39",
        info: "#00bcd4",
        success: "#4caf50"
      },
      dark: {
        primary: "#8bc34a",
        secondary: "#2196f3",
        accent: "#3f51b5",
        error: "#f44336",
        warning: "#cddc39",
        info: "#00bcd4",
        success: "#4caf50"
      }
    }
  }
});
