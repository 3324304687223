export const login = {
  state: {
    authStatus: localStorage.getItem("authStatus") === "1" || 0 ? 1 : 0,
    serverStatus: 1
  },
  mutations: {
    login(state) {
      state.authStatus = 1;
      localStorage.setItem("authStatus", "1");
    },
    logout(state) {
      state.authStatus = 0;
      localStorage.setItem("authStatus", "0");
    },
    server_ok(state) {
      state.serverStatus = 1;
    },
    server_error(state) {
      state.serverStatus = 0;
    }
  },
  actions: {
    login({ commit }) {
      commit("login");
    },
    logout({ commit }, vueInstance) {
      vueInstance.$http
        .post("auth/logout.php", this.formdata)
        .then(function(response) {
          if (response.data.isLoggedOut === "true") commit("logout");
        })
        .catch(function(error) {});
    },
    server_ok({ commit }) {
      commit("server_ok");
    },
    server_error({ commit }) {
      commit("server_error");
    }
  },
  getters: {
    isLoggedIn: state => state.authStatus === 1,
    isServerOk: state => state.serverStatus === 1
  }
};
